/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-dupe-else-if */

import React, { useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Frquency, globalFrquencyData } from "../reusable-Components/Frequency";
import axios from "axios";
import { HttpHeaders } from "../shared/HttpHeaders";
import { IConfigurationType } from "../core/view-Model/configuration.model";
import LoadingSpinner from "./LoadingSpinner";
import { Button, Checkbox, Confirm, DropDownNative, FormField, Input, MultiSelect } from "@tag/tag-components-react-v4";
import "../assets/css/config.scss";
import "../assets/css/common.scss";

function Configuration({
  openConfigurationScreen,
  SetopenConfigurationScreen,
}: any) {
  const Headers = HttpHeaders();
  const [callConfigrationData, setCallConfigrationData] = useState(true);
  const [configurationData, setConfigurationData] = React.useState<
    IConfigurationType[]
  >([]);
  const [isdisabled, setdisabled] = useState<boolean>(true);
  const [buttonDisbled, setButtonDisbled] = useState(true);
  const [showSaveButton, setshowSaveButton] = useState(false);
  const [showSetDefaulrConfirmationPopup, setShowSetDefaulrConfirmationPopup] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [userLicenceOption, setUserLicenceOption] = useState("Hours");
  const [userVehicleOption, setUserVehicleOption] = useState("Hours");
  const [adminLicenceOption, setAdminLicenceOption] = useState("Days");
  const [adminVehicleOption, setAdminVehicleOption] = useState("Days");
  const [expiredDetailsOption, setExpiredDetailsOption] = useState("Years");
  const [motDocumentCheck, setMotDocumentCheck] = useState(false);
  const [taxDocumentCheck, setTaxDocumentCheck] = useState(false);
  const [manualLicenceCheck, SetManualLicenceCheck] = useState(false);
  const [manualVehicleCheck, SetManualVehicleCheck] = useState(false);

  const [endAdditionOfLicenceCheck, setEndAdditionOfLicenceCheck] = useState(false);
  const [endRecheckRefreshLicenceCheck, setEndRecheckRefreshLicenceCheck] = useState(false);
  const [manualVehicleApprovedCheck, setManualVehicleApprovedCheck] = useState(false);
  const [manualLicenceApprovalCheck, setmanualLicenceApprovalCheck] = useState(false);
  const [provisionalLicencesCheck, setProvisionalLicencesCheck] = useState(false);

  const [userLicenceFrequency, setUserLicenceFrequency] = useState(0);
  const [adminLicenceFrequency, setAdminLicenceFrequency] = useState(0);
  const [userVehicleFrequency, setUserVehicleFrequency] = useState(0);
  const [adminVehicleFrequency, setAdminVehicleFrequency] = useState(0);
  const [expiredDetailsFrequency, setExpiredDetailsFrequency] = useState(0);

  const [highMinLicence, setHighMinLicence] = useState(0);
  const [highMaxLicence, setHighMaxLicence] = useState(0);
  const [mediumMinLicence, setMediumMinLicence] = useState(0);
  const [mediumMaxLicence, setMediumMaxLicence] = useState(0);
  const [lowMinLicence, setLowMinLicence] = useState(0);
  const [lowMaxLicence, setLowMaxLicence] = useState(0);

  const [highMinPhotoCard, setHighMinPhotoCard] = useState(0);
  const [highMaxPhotoCard, setHighMaxPhotoCard] = useState(0);
  const [mediumMinPhotoCard, setMediumMinPhotoCard] = useState(0);
  const [mediumMaxPhotoCard, setMediumMaxPhotoCard] = useState(0);
  const [lowMinPhotoCard, setLowMinPhotoCard] = useState(0);
  const [lowMaxPhotoCard, setLowMaxPhotoCard] = useState(0);

  const [highMinTaxDue, setHighMinTaxDue] = useState(0);
  const [highMaxTaxDue, setHighMaxTaxDue] = useState(0);
  const [mediumMinTaxDue, setMediumMinTaxDue] = useState(0);
  const [mediumMaxTaxDue, setMediumMaxTaxDue] = useState(0);
  const [lowMinTaxDue, setLowMinTaxDue] = useState(0);
  const [lowMaxTaxDue, setLowMaxTaxDue] = useState(0);

  const [highMinMOT, setHighMinMOT] = useState(0);
  const [highMaxMOT, setHighMaxMOT] = useState(0);
  const [mediumMinMOT, setMediumMinMOT] = useState(0);
  const [mediumMaxMOT, setMediumMaxMOT] = useState(0);
  const [lowMinMOT, setLowMinMOT] = useState(0);
  const [lowMaxMOT, setLowMaxMOT] = useState(0);

  const [highMinInsurance, setHighMinInsurance] = useState(0);
  const [highMaxInsurance, setHighMaxInsurance] = useState(0);
  const [mediumMinInsurance, setMediumMinInsurance] = useState(0);
  const [mediumMaxInsurance, setMediumMaxInsurance] = useState(0);
  const [lowMinInsurance, setLowMinInsurance] = useState(0);
  const [lowMaxInsurance, setLowMaxInsurance] = useState(0);

  const [callFrequency, setCallFrequency] = useState(false);
  const [organizationTagsData, setOrganizationTagsData] = useState([]);
  const [orgTagsData, setOrgTagsData] = useState<string[]>([]);
  const organizationTagsAllData = useRef('');
  
  React.useEffect(() => {
    if (callConfigrationData) {
      getConfigrationData();
    }
  }, []);

  const onBtnEditClick = (e: any) => {
    setButtonDisbled(false);
    setdisabled(false);
    setshowSaveButton(true);
    setShowSetDefaulrConfirmationPopup(false);
  };

  const onBtnCancelClick = (e: any) => {
    setButtonDisbled(false);
    setdisabled(true);
    setshowSaveButton(false);
    setShowSetDefaulrConfirmationPopup(false);
    getConfigrationData();
  };

  const onBtnSaveclick = (e: any) => {
    setConfigurationData([]);
    configurationData.push({
      doc_configuration_primary: 1,
      doc_configuration_name: "Driving Licence",
      doc_configuration_type: "Frequency",
      doc_configuration_freq_for: "User",
      doc_configuration_level: undefined,
      doc_configuration_freq_from: userLicenceFrequency,
      doc_configuration_freq_to: undefined,
      doc_configuration_duration_type: userLicenceOption,
      doc_configuration_isrequired: false,
      doc_organization_Tags: undefined,
      doc_email_alert: false,
    });
    configurationData.push({
      doc_configuration_primary: 2,
      doc_configuration_name: "Vehicle",
      doc_configuration_type: "Frequency",
      doc_configuration_freq_for: "User",
      doc_configuration_level: undefined,
      doc_configuration_freq_from: userVehicleFrequency,
      doc_configuration_freq_to: undefined,
      doc_configuration_duration_type: userVehicleOption,
      doc_configuration_isrequired: false,
      doc_organization_Tags: undefined,
      doc_email_alert: false,
    });

    configurationData.push({
      doc_configuration_primary: 3,
      doc_configuration_name: "Driving Licence",
      doc_configuration_type: "Frequency",
      doc_configuration_freq_for: "Admin",
      doc_configuration_level: undefined,
      doc_configuration_freq_from: adminLicenceFrequency,
      doc_configuration_freq_to: undefined,
      doc_configuration_duration_type: "Days",
      doc_configuration_isrequired: false,
      doc_organization_Tags: undefined,
      doc_email_alert: false,
    });

    configurationData.push({
      doc_configuration_primary: 4,
      doc_configuration_name: "Vehicle",
      doc_configuration_type: "Frequency",
      doc_configuration_freq_for: "Admin",
      doc_configuration_level: undefined,
      doc_configuration_freq_from: adminVehicleFrequency,
      doc_configuration_freq_to: undefined,
      doc_configuration_duration_type: "Days",
      doc_configuration_isrequired: false,
      doc_organization_Tags: undefined,
      doc_email_alert: false,
    });

    configurationData.push({
      doc_configuration_primary: 5,
      doc_configuration_name: "Expired Details",
      doc_configuration_type: "Frequency",
      doc_configuration_freq_for: undefined,
      doc_configuration_level: undefined,
      doc_configuration_freq_from: expiredDetailsFrequency,
      doc_configuration_freq_to: undefined,
      doc_configuration_duration_type: expiredDetailsOption,
      doc_configuration_isrequired: false,
      doc_organization_Tags: undefined,
      doc_email_alert: false,
    });

    configurationData.push({
      doc_configuration_primary: 6,
      doc_configuration_name: "MOT",
      doc_configuration_type: "Document",
      doc_configuration_freq_for: "",
      doc_configuration_level: "",
      doc_configuration_freq_from: 0,
      doc_configuration_freq_to: 0,
      doc_configuration_duration_type: "",
      doc_configuration_isrequired: motDocumentCheck,
      doc_organization_Tags: undefined,
      doc_email_alert: false,
    });

    configurationData.push({
      doc_configuration_primary: 7,
      doc_configuration_name: "TAX",
      doc_configuration_type: "Document",
      doc_configuration_freq_for: "",
      doc_configuration_level: "",
      doc_configuration_freq_from: 0,
      doc_configuration_freq_to: 0,
      doc_configuration_duration_type: "",
      doc_configuration_isrequired: taxDocumentCheck,
      doc_organization_Tags: undefined,
      doc_email_alert: false,
    });

    for (let i = 0, j = 8; i < globalFrquencyData.length; i++, j++) {
      configurationData.push({
        doc_configuration_primary: j,
        doc_configuration_name: globalFrquencyData[i].Config_Name,
        doc_configuration_type: "Expiry",
        doc_configuration_freq_for: undefined,
        doc_configuration_level: globalFrquencyData[i].Level,
        doc_configuration_freq_from: globalFrquencyData[i].Freq_From,
        doc_configuration_freq_to: globalFrquencyData[i].Freq_To,
        doc_configuration_duration_type: "Days",
        doc_configuration_isrequired: false,
        doc_organization_Tags: undefined,
        doc_email_alert: false,
      });
    }

    configurationData.push({
      doc_configuration_primary: 23,
      doc_configuration_name: "Manual Licence",
      doc_configuration_type: "Document",
      doc_configuration_freq_for: "",
      doc_configuration_level: "",
      doc_configuration_freq_from: 0,
      doc_configuration_freq_to: 0,
      doc_configuration_duration_type: "",
      doc_configuration_isrequired: manualLicenceCheck,
      doc_organization_Tags: undefined,
      doc_email_alert: false,
    });

    configurationData.push({
      doc_configuration_primary: 24,
      doc_configuration_name: "Manual Vehicle",
      doc_configuration_type: "Document",
      doc_configuration_freq_for: "",
      doc_configuration_level: "",
      doc_configuration_freq_from: 0,
      doc_configuration_freq_to: 0,
      doc_configuration_duration_type: "",
      doc_configuration_isrequired: manualVehicleCheck,
      doc_organization_Tags: undefined,
      doc_email_alert: false,
    });

    configurationData.push({
      doc_configuration_primary: 25,
      doc_configuration_name: "Organization Tags",
      doc_configuration_type: "Tags",
      doc_configuration_freq_for: "",
      doc_configuration_level: "",
      doc_configuration_freq_from: 0,
      doc_configuration_freq_to: 0,
      doc_configuration_duration_type: "",
      doc_configuration_isrequired: false,
      doc_organization_Tags: organizationTagsAllData.current,
      doc_email_alert: false,
    });

    configurationData.push({
      doc_configuration_primary: 26,
      doc_configuration_name: "Endorsement Addition of Licence",
      doc_configuration_type: "Email",
      doc_configuration_freq_for: "",
      doc_configuration_level: "",
      doc_configuration_freq_from: 0,
      doc_configuration_freq_to: 0,
      doc_configuration_duration_type: "",
      doc_configuration_isrequired: false,
      doc_organization_Tags: undefined,
      doc_email_alert: endAdditionOfLicenceCheck,
    });

    configurationData.push({
      doc_configuration_primary: 27,
      doc_configuration_name: "Endorsement Recheck_Refresh Licence",
      doc_configuration_type: "Email",
      doc_configuration_freq_for: "",
      doc_configuration_level: "",
      doc_configuration_freq_from: 0,
      doc_configuration_freq_to: 0,
      doc_configuration_duration_type: "",
      doc_configuration_isrequired: false,
      doc_organization_Tags: undefined,
      doc_email_alert: endRecheckRefreshLicenceCheck,
    });

    configurationData.push({
      doc_configuration_primary: 28,
      doc_configuration_name: "Manual Vehicle Approved",
      doc_configuration_type: "Email",
      doc_configuration_freq_for: "",
      doc_configuration_level: "",
      doc_configuration_freq_from: 0,
      doc_configuration_freq_to: 0,
      doc_configuration_duration_type: "",
      doc_configuration_isrequired: false,
      doc_organization_Tags: undefined,
      doc_email_alert: manualVehicleApprovedCheck,
    });

    configurationData.push({
      doc_configuration_primary: 29,
      doc_configuration_name: "Manual Licence Approval",
      doc_configuration_type: "Email",
      doc_configuration_freq_for: "",
      doc_configuration_level: "",
      doc_configuration_freq_from: 0,
      doc_configuration_freq_to: 0,
      doc_configuration_duration_type: "",
      doc_configuration_isrequired: false,
      doc_organization_Tags: undefined,
      doc_email_alert: manualLicenceApprovalCheck,
    });

    configurationData.push({
      doc_configuration_primary: 30,
      doc_configuration_name: "Provisional Licences",
      doc_configuration_type: "Email",
      doc_configuration_freq_for: "",
      doc_configuration_level: "",
      doc_configuration_freq_from: 0,
      doc_configuration_freq_to: 0,
      doc_configuration_duration_type: "",
      doc_configuration_isrequired: false,
      doc_organization_Tags: undefined,
      doc_email_alert: provisionalLicencesCheck,
    });

    saveConfigurations();
  };

  const saveConfigurations = () => {
    setIsLoading(true);
    axios
      .post(`Admin/SaveConfigurationValues`, configurationData, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          setIsLoading(false);
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                afterUpdateSetVale(response.data.data);
                setButtonDisbled(true);
                setdisabled(true);
                setshowSaveButton(false);
              }
            }
          }
        }
      })
      .catch((ex) => {
        alert("danger - Getting some error when save configurations!");
      });
  };

  const onBtnSetDefaultClick = (e: any) => {
    setShowSetDefaulrConfirmationPopup(true);
  };

  const onBtnConfirmationSaveClick = (e: any) => {
    setShowSetDefaulrConfirmationPopup(false);
    setCallFrequency(false);
    bindDefaultConfigurationDetails();
  };

  const bindDefaultConfigurationDetails = () => {
    setIsLoading(true);
    axios
      .get(`Admin/GetDefaultConfiguration/`, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                for (let i = 0; i < response.data.data.length; i++) {
                  let configurationType = response.data.data[i]["type"];
                  let configurationName = response.data.data[i]["name"];
                  let configurationFreqFor = response.data.data[i]["freQ_FOR"];
                  let configurationLevel = response.data.data[i]["level"];
                  let configurationIsRequired = response.data.data[i]["isrequired"];

                  if (configurationType === "Frequency") {
                    if (configurationName === "Driving Licence") {
                      if (configurationFreqFor === "Admin") {
                        setAdminLicenceFrequency(
                          response.data.data[i]["freQ_FROM"]
                        );
                        setAdminLicenceOption(
                          response.data.data[i]["duratioN_TYPE"]
                        );
                      } else {
                        setUserLicenceFrequency(
                          response.data.data[i]["freQ_FROM"]
                        );
                        setUserLicenceOption(
                          response.data.data[i]["duratioN_TYPE"]
                        );
                      }
                    } else if (configurationName === "Vehicle") {
                      if (configurationFreqFor === "Admin") {
                        setAdminVehicleFrequency(
                          response.data.data[i]["freQ_FROM"]
                        );
                        setAdminVehicleOption(
                          response.data.data[i]["duratioN_TYPE"]
                        );
                      } else {
                        setUserVehicleFrequency(
                          response.data.data[i]["freQ_FROM"]
                        );
                        setUserVehicleOption(
                          response.data.data[i]["duratioN_TYPE"]
                        );
                      }
                    } else {
                      setExpiredDetailsFrequency(
                        response.data.data[i]["freQ_FROM"]
                      );
                      setExpiredDetailsOption(
                        response.data.data[i]["duratioN_TYPE"]
                      );
                    }
                  }
                  else if (configurationType === "Document") {
                    if (configurationName === "MOT") {
                      if (configurationIsRequired === true) {
                        setMotDocumentCheck(true);
                      } else {
                        setMotDocumentCheck(false);
                      }
                    }
                    if (configurationName === "TAX") {
                      if (configurationIsRequired === true) {
                        setTaxDocumentCheck(true);
                      } else {
                        setTaxDocumentCheck(false);
                      }
                    }
                    if (configurationName === "Manual Licence") {
                      if (configurationIsRequired === true) {
                        SetManualLicenceCheck(true);
                      } else {
                        SetManualLicenceCheck(false);
                      }
                    }
                    if (configurationName === "Manual Vehicle") {
                      if (configurationIsRequired === true) {
                        SetManualVehicleCheck(true);
                      } else {
                        SetManualVehicleCheck(false);
                      }
                    }
                  }
                  else if (configurationType === "Tags") {
                    setOrgTagsData([]);
                    let OrgTagsDataResponse = organizationTagsData.map((item: { value: any; name: any; }) => ({
                      name: item.value,
                      value: item.name,
                      disabled: false
                    }));
                    organizationTagsAllData.current = "";
                  }
                  else if (configurationType === "Email") {
                    if (configurationName === "Endorsement Addition of Licence") {
                      if (response.data.data[i].emaiL_ALERT === true) {
                        setEndAdditionOfLicenceCheck(true);
                      } else {
                        setEndAdditionOfLicenceCheck(false);
                      }
                    } else if (configurationName === "Endorsement Recheck_Refresh Licence") {
                      if (response.data.data[i].emaiL_ALERT === true) {
                        setEndRecheckRefreshLicenceCheck(true);
                      } else {
                        setEndRecheckRefreshLicenceCheck(false);
                      }
                    } else if (configurationName === "Manual Vehicle Approved") {
                      if (response.data.data[i].emaiL_ALERT === true) {
                        setManualVehicleApprovedCheck(true);
                      } else {
                        setManualVehicleApprovedCheck(false);
                      }
                    } else if (configurationName === "Manual Licence Approval") {
                      if (response.data.data[i].emaiL_ALERT === true) {
                        setmanualLicenceApprovalCheck(true);
                      } else {
                        setmanualLicenceApprovalCheck(false);
                      }
                    } else if (configurationName === "Provisional Licences") {
                      if (response.data.data[i].emaiL_ALERT === true) {
                        setProvisionalLicencesCheck(true);
                      } else {
                        setProvisionalLicencesCheck(false);
                      }
                    }
                  }
                  else {
                    if (configurationName === "Driving Licence") {
                      if (configurationLevel === "High") {
                        //globalFrquencyData
                        let frquencyIndex = globalFrquencyData.findIndex(
                          (x) =>
                            x.Config_Name === configurationName &&
                            x.Level === configurationLevel
                        );
                        globalFrquencyData[frquencyIndex]["Freq_From"] =
                          response.data.data[i]["freQ_FROM"];
                        globalFrquencyData[frquencyIndex]["Freq_To"] =
                          response.data.data[i]["freQ_TO"];

                        setHighMinLicence(response.data.data[i]["freQ_FROM"]);
                        setHighMaxLicence(response.data.data[i]["freQ_TO"]);
                      } else if (configurationLevel === "Medium") {
                        //globalFrquencyData
                        let frquencyIndex = globalFrquencyData.findIndex(
                          (x) =>
                            x.Config_Name === configurationName &&
                            x.Level === configurationLevel
                        );
                        globalFrquencyData[frquencyIndex]["Freq_From"] =
                          response.data.data[i]["freQ_FROM"];
                        globalFrquencyData[frquencyIndex]["Freq_To"] =
                          response.data.data[i]["freQ_TO"];

                        setMediumMinLicence(response.data.data[i]["freQ_FROM"]);
                        setMediumMaxLicence(response.data.data[i]["freQ_TO"]);
                      } else {
                        //globalFrquencyData
                        let frquencyIndex = globalFrquencyData.findIndex(
                          (x) =>
                            x.Config_Name === configurationName &&
                            x.Level === configurationLevel
                        );
                        globalFrquencyData[frquencyIndex]["Freq_From"] =
                          response.data.data[i]["freQ_FROM"];
                        globalFrquencyData[frquencyIndex]["Freq_To"] =
                          response.data.data[i]["freQ_TO"];

                        setLowMinLicence(response.data.data[i]["freQ_FROM"]);
                        setLowMaxLicence(response.data.data[i]["freQ_TO"]);
                      }
                    } else if (configurationName === "TAX") {
                      if (configurationLevel === "High") {
                        //globalFrquencyData
                        let frquencyIndex = globalFrquencyData.findIndex(
                          (x) =>
                            x.Config_Name === configurationName &&
                            x.Level === configurationLevel
                        );
                        globalFrquencyData[frquencyIndex]["Freq_From"] =
                          response.data.data[i]["freQ_FROM"];
                        globalFrquencyData[frquencyIndex]["Freq_To"] =
                          response.data.data[i]["freQ_TO"];

                        setHighMinTaxDue(response.data.data[i]["freQ_FROM"]);
                        setHighMaxTaxDue(response.data.data[i]["freQ_TO"]);
                      } else if (configurationLevel === "Medium") {
                        //globalFrquencyData
                        let frquencyIndex = globalFrquencyData.findIndex(
                          (x) =>
                            x.Config_Name === configurationName &&
                            x.Level === configurationLevel
                        );
                        globalFrquencyData[frquencyIndex]["Freq_From"] =
                          response.data.data[i]["freQ_FROM"];
                        globalFrquencyData[frquencyIndex]["Freq_To"] =
                          response.data.data[i]["freQ_TO"];

                        setMediumMinTaxDue(response.data.data[i]["freQ_FROM"]);
                        setMediumMaxTaxDue(response.data.data[i]["freQ_TO"]);
                      } else {
                        //globalFrquencyData
                        let frquencyIndex = globalFrquencyData.findIndex(
                          (x) =>
                            x.Config_Name === configurationName &&
                            x.Level === configurationLevel
                        );
                        globalFrquencyData[frquencyIndex]["Freq_From"] =
                          response.data.data[i]["freQ_FROM"];
                        globalFrquencyData[frquencyIndex]["Freq_To"] =
                          response.data.data[i]["freQ_TO"];

                        setLowMinTaxDue(response.data.data[i]["freQ_FROM"]);
                        setLowMaxTaxDue(response.data.data[i]["freQ_TO"]);
                      }
                    } else if (configurationName === "MOT") {
                      if (configurationLevel === "High") {
                        //globalFrquencyData
                        let frquencyIndex = globalFrquencyData.findIndex(
                          (x) =>
                            x.Config_Name === configurationName &&
                            x.Level === configurationLevel
                        );
                        globalFrquencyData[frquencyIndex]["Freq_From"] =
                          response.data.data[i]["freQ_FROM"];
                        globalFrquencyData[frquencyIndex]["Freq_To"] =
                          response.data.data[i]["freQ_TO"];

                        setHighMinMOT(response.data.data[i]["freQ_FROM"]);
                        setHighMaxMOT(response.data.data[i]["freQ_TO"]);
                      } else if (configurationLevel === "Medium") {
                        //globalFrquencyData
                        let frquencyIndex = globalFrquencyData.findIndex(
                          (x) =>
                            x.Config_Name === configurationName &&
                            x.Level === configurationLevel
                        );
                        globalFrquencyData[frquencyIndex]["Freq_From"] =
                          response.data.data[i]["freQ_FROM"];
                        globalFrquencyData[frquencyIndex]["Freq_To"] =
                          response.data.data[i]["freQ_TO"];

                        setMediumMinMOT(response.data.data[i]["freQ_FROM"]);
                        setMediumMaxMOT(response.data.data[i]["freQ_TO"]);
                      } else {
                        //globalFrquencyData
                        let frquencyIndex = globalFrquencyData.findIndex(
                          (x) =>
                            x.Config_Name === configurationName &&
                            x.Level === configurationLevel
                        );
                        globalFrquencyData[frquencyIndex]["Freq_From"] =
                          response.data.data[i]["freQ_FROM"];
                        globalFrquencyData[frquencyIndex]["Freq_To"] =
                          response.data.data[i]["freQ_TO"];

                        setLowMinMOT(response.data.data[i]["freQ_FROM"]);
                        setLowMaxMOT(response.data.data[i]["freQ_TO"]);
                      }
                    } else if (configurationName === "Photocard") {
                      if (configurationLevel === "High") {
                        //globalFrquencyData
                        let frquencyIndex = globalFrquencyData.findIndex(
                          (x) =>
                            x.Config_Name === configurationName &&
                            x.Level === configurationLevel
                        );
                        globalFrquencyData[frquencyIndex]["Freq_From"] =
                          response.data.data[i]["freQ_FROM"];
                        globalFrquencyData[frquencyIndex]["Freq_To"] =
                          response.data.data[i]["freQ_TO"];

                        setHighMinPhotoCard(response.data.data[i]["freQ_FROM"]);
                        setHighMaxPhotoCard(response.data.data[i]["freQ_TO"]);
                      } else if (configurationLevel === "Medium") {
                        //globalFrquencyData
                        let frquencyIndex = globalFrquencyData.findIndex(
                          (x) =>
                            x.Config_Name === configurationName &&
                            x.Level === configurationLevel
                        );
                        globalFrquencyData[frquencyIndex]["Freq_From"] =
                          response.data.data[i]["freQ_FROM"];
                        globalFrquencyData[frquencyIndex]["Freq_To"] =
                          response.data.data[i]["freQ_TO"];

                        setMediumMinPhotoCard(
                          response.data.data[i]["freQ_FROM"]
                        );
                        setMediumMaxPhotoCard(response.data.data[i]["freQ_TO"]);
                      } else {
                        //globalFrquencyData
                        let frquencyIndex = globalFrquencyData.findIndex(
                          (x) =>
                            x.Config_Name === configurationName &&
                            x.Level === configurationLevel
                        );
                        globalFrquencyData[frquencyIndex]["Freq_From"] =
                          response.data.data[i]["freQ_FROM"];
                        globalFrquencyData[frquencyIndex]["Freq_To"] =
                          response.data.data[i]["freQ_TO"];

                        setLowMinPhotoCard(response.data.data[i]["freQ_FROM"]);
                        setLowMaxPhotoCard(response.data.data[i]["freQ_TO"]);
                      }
                    } else {
                      if (configurationLevel === "High") {
                        //globalFrquencyData
                        let frquencyIndex = globalFrquencyData.findIndex(
                          (x) =>
                            x.Config_Name === configurationName &&
                            x.Level === configurationLevel
                        );
                        globalFrquencyData[frquencyIndex]["Freq_From"] =
                          response.data.data[i]["freQ_FROM"];
                        globalFrquencyData[frquencyIndex]["Freq_To"] =
                          response.data.data[i]["freQ_TO"];

                        setHighMinInsurance(response.data.data[i]["freQ_FROM"]);
                        setHighMaxInsurance(response.data.data[i]["freQ_TO"]);
                      } else if (configurationLevel === "Medium") {
                        //globalFrquencyData
                        let frquencyIndex = globalFrquencyData.findIndex(
                          (x) =>
                            x.Config_Name === configurationName &&
                            x.Level === configurationLevel
                        );
                        globalFrquencyData[frquencyIndex]["Freq_From"] =
                          response.data.data[i]["freQ_FROM"];
                        globalFrquencyData[frquencyIndex]["Freq_To"] =
                          response.data.data[i]["freQ_TO"];

                        setMediumMinInsurance(
                          response.data.data[i]["freQ_FROM"]
                        );
                        setMediumMaxInsurance(response.data.data[i]["freQ_TO"]);
                      } else {
                        //globalFrquencyData
                        let frquencyIndex = globalFrquencyData.findIndex(
                          (x) =>
                            x.Config_Name === configurationName &&
                            x.Level === configurationLevel
                        );
                        globalFrquencyData[frquencyIndex]["Freq_From"] =
                          response.data.data[i]["freQ_FROM"];
                        globalFrquencyData[frquencyIndex]["Freq_To"] =
                          response.data.data[i]["freQ_TO"];

                        setLowMinInsurance(response.data.data[i]["freQ_FROM"]);
                        setLowMaxInsurance(response.data.data[i]["freQ_TO"]);
                      }
                    }
                  }
                }
                setCallFrequency(true);
                setIsLoading(false);
              }
              setIsLoading(false);
            }
          }
        }
        setIsLoading(false);
      })
      .catch((ex) => {
        console.log("Getting some API error when get default configuration values!");
      });
  };

  const onBtnConfirmationCancelClick = (e: any) => {
    setShowSetDefaulrConfirmationPopup(false);
  };

  const getConfigrationData = () => {
    setIsLoading(true);
    axios
      .get(`Admin/GetConfiguration/`, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              setIsLoading(false);
              setCallConfigrationData(false);
              if (response.data.data.length > 0) {
                setCallFrequency(false);
                for (let i = 0; i < response.data.data.length; i++) {
                  let configurationType = response.data.data[i]["type"];
                  let configurationName = response.data.data[i]["name"];
                  let configurationFreqFor = response.data.data[i]["freQ_FOR"];
                  let configurationLevel = response.data.data[i]["level"];
                  let configurationIsRequired = response.data.data[i]["isrequired"];

                  if (configurationType === "Frequency") {
                    if (configurationName === "Driving Licence") {
                      if (configurationFreqFor === "Admin") {
                        setAdminLicenceFrequency(
                          response.data.data[i]["freQ_FROM"]
                        );
                        setAdminLicenceOption(
                          response.data.data[i]["duratioN_TYPE"]
                        );
                      } else {
                        setUserLicenceFrequency(
                          response.data.data[i]["freQ_FROM"]
                        );
                        setUserLicenceOption(
                          response.data.data[i]["duratioN_TYPE"]
                        );
                      }
                    } else if (configurationName === "Vehicle") {
                      if (configurationFreqFor === "Admin") {
                        setAdminVehicleFrequency(
                          response.data.data[i]["freQ_FROM"]
                        );
                        setAdminVehicleOption(
                          response.data.data[i]["duratioN_TYPE"]
                        );
                      } else {
                        setUserVehicleFrequency(
                          response.data.data[i]["freQ_FROM"]
                        );
                        setUserVehicleOption(
                          response.data.data[i]["duratioN_TYPE"]
                        );
                      }
                    } else {
                      setExpiredDetailsFrequency(
                        response.data.data[i]["freQ_FROM"]
                      );
                      setExpiredDetailsOption(
                        response.data.data[i]["duratioN_TYPE"]
                      );
                    }
                  }
                  else if (configurationType === "Document") {
                    if (configurationName === "MOT") {
                      if (response.data.data[i]["isrequired"] === true) {
                        setMotDocumentCheck(true);
                      } else {
                        setMotDocumentCheck(false);
                      }
                    }
                    if (configurationName === "Manual Licence") {
                      if (response.data.data[i]["isrequired"] === true) {
                        SetManualLicenceCheck(true);
                      } else {
                        SetManualLicenceCheck(false);
                      }
                    }
                    if (configurationName === "Manual Vehicle") {
                      if (response.data.data[i]["isrequired"] === true) {
                        SetManualVehicleCheck(true);
                      } else {
                        SetManualVehicleCheck(false);
                      }
                    }
                  }
                  else if (configurationType === "Tags") {
                    organizationTagsAllData.current = response.data.data[i].organizatioN_TAGS;
                    if (organizationTagsAllData.current != null) {
                      const orgTagsData = response.data.data[i].organizatioN_TAGS.split(", ");
                      setOrgTagsData(orgTagsData);
                      if (response.data.organizationTagsData != null) {
                        let responseDataTag = response.data.organizationTagsData.userProperties.items;
                        if(orgTagsData.length != 3){
                          let OrgTagsDataResponse = responseDataTag.map((item: { id: any; name: any; }) => ({
                            name: item.id,
                            value: item.name
                          }))
                          setOrganizationTagsData(OrgTagsDataResponse);
                        } else {
                          let OrgTagsDataResponse = responseDataTag.map((item: { id: any; name: any; }) => ({
                            name: item.id,
                            value: item.name,
                            disabled: true
                          }))
                          setOrganizationTagsData(OrgTagsDataResponse);
                        }
                      }
                    } else {
                      setOrgTagsData([]);
                      if(response.data.organizationTagsData) {
                        let responseDataTag = response.data.organizationTagsData.userProperties.items;
                        let OrgTagsDataResponse = responseDataTag.map((item: { id: any; name: any; }) => ({
                          name: item.id,
                          value: item.name
                        }))
                        setOrganizationTagsData(OrgTagsDataResponse);
                      }
                    } 
                  }
                  else if (configurationType === "Email") {
                    if (configurationName === "Endorsement Addition of Licence") {
                      if (response.data.data[i].emaiL_ALERT === true) {
                        setEndAdditionOfLicenceCheck(true);
                      } else {
                        setEndAdditionOfLicenceCheck(false);
                      }
                    } else if (configurationName === "Endorsement Recheck_Refresh Licence") {
                      if (response.data.data[i].emaiL_ALERT === true) {
                        setEndRecheckRefreshLicenceCheck(true);
                      } else {
                        setEndRecheckRefreshLicenceCheck(false);
                      }
                    } else if (configurationName === "Manual Vehicle Approved") {
                      if (response.data.data[i].emaiL_ALERT === true) {
                        setManualVehicleApprovedCheck(true);
                      } else {
                        setManualVehicleApprovedCheck(false);
                      }
                    } else if (configurationName === "Manual Licence Approval") {
                      if (response.data.data[i].emaiL_ALERT === true) {
                        setmanualLicenceApprovalCheck(true);
                      } else {
                        setmanualLicenceApprovalCheck(false);
                      }
                    } else if (configurationName === "Provisional Licences") {
                      if (response.data.data[i].emaiL_ALERT === true) {
                        setProvisionalLicencesCheck(true);
                      } else {
                        setProvisionalLicencesCheck(false);
                      }
                    }
                  }
                  else {
                    if (configurationName === "Driving Licence") {
                      if (configurationLevel === "High") {
                        setHighMinLicence(response.data.data[i]["freQ_FROM"]);
                        setHighMaxLicence(response.data.data[i]["freQ_TO"]);
                      } else if (configurationLevel === "Medium") {
                        setMediumMinLicence(response.data.data[i]["freQ_FROM"]);
                        setMediumMaxLicence(response.data.data[i]["freQ_TO"]);
                      } else {
                        setLowMinLicence(response.data.data[i]["freQ_FROM"]);
                        setLowMaxLicence(response.data.data[i]["freQ_TO"]);
                      }
                    } else if (configurationName === "TAX") {
                      if (configurationLevel === "High") {
                        setHighMinTaxDue(response.data.data[i]["freQ_FROM"]);
                        setHighMaxTaxDue(response.data.data[i]["freQ_TO"]);
                      } else if (configurationLevel === "Medium") {
                        setMediumMinTaxDue(response.data.data[i]["freQ_FROM"]);
                        setMediumMaxTaxDue(response.data.data[i]["freQ_TO"]);
                      } else {
                        setLowMinTaxDue(response.data.data[i]["freQ_FROM"]);
                        setLowMaxTaxDue(response.data.data[i]["freQ_TO"]);
                      }
                    } else if (configurationName === "MOT") {
                      if (configurationLevel === "High") {
                        setHighMinMOT(response.data.data[i]["freQ_FROM"]);
                        setHighMaxMOT(response.data.data[i]["freQ_TO"]);
                      } else if (configurationLevel === "Medium") {
                        setMediumMinMOT(response.data.data[i]["freQ_FROM"]);
                        setMediumMaxMOT(response.data.data[i]["freQ_TO"]);
                      } else {
                        setLowMinMOT(response.data.data[i]["freQ_FROM"]);
                        setLowMaxMOT(response.data.data[i]["freQ_TO"]);
                      }
                    } else if (configurationName === "Photocard") {
                      if (configurationLevel === "High") {
                        setHighMinPhotoCard(response.data.data[i]["freQ_FROM"]);
                        setHighMaxPhotoCard(response.data.data[i]["freQ_TO"]);
                      } else if (configurationLevel === "Medium") {
                        setMediumMinPhotoCard(
                          response.data.data[i]["freQ_FROM"]
                        );
                        setMediumMaxPhotoCard(response.data.data[i]["freQ_TO"]);
                      } else {
                        setLowMinPhotoCard(response.data.data[i]["freQ_FROM"]);
                        setLowMaxPhotoCard(response.data.data[i]["freQ_TO"]);
                      }
                    } else {
                      if (configurationLevel === "High") {
                        setHighMinInsurance(response.data.data[i]["freQ_FROM"]);
                        setHighMaxInsurance(response.data.data[i]["freQ_TO"]);
                      } else if (configurationLevel === "Medium") {
                        setMediumMinInsurance(
                          response.data.data[i]["freQ_FROM"]
                        );
                        setMediumMaxInsurance(response.data.data[i]["freQ_TO"]);
                      } else {
                        setLowMinInsurance(response.data.data[i]["freQ_FROM"]);
                        setLowMaxInsurance(response.data.data[i]["freQ_TO"]);
                      }
                    }
                  }
                }
                setCallFrequency(true);
              }
            }
          }
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when get configuration values!");
      });
  };

  function afterUpdateSetVale(data: any) {
    for (
      let i = 0, j = 6;
      i < data.length;
      i++, j++
    ) {
      if (
        data[i].doC_CONFIGURATION_NAME === "Insurance"
      ) {
        if (
          data[i].doC_CONFIGURATION_LEVEL === "High"
        ) {
          setHighMinInsurance(
            data[i].doC_CONFIGURATION_FREQ_FROM
          );
          setHighMaxInsurance(
            data[i].doC_CONFIGURATION_FREQ_TO
          );
        }

        else if (
          data[i].doC_CONFIGURATION_LEVEL === "Medium"
        ) {
          setMediumMinInsurance(
            data[i].doC_CONFIGURATION_FREQ_FROM
          );
          setMediumMaxInsurance(
            data[i].doC_CONFIGURATION_FREQ_TO
          );
        }
        else if (
          data[i].doC_CONFIGURATION_LEVEL === "Low"
        ) {
          setLowMinInsurance(
            data[i].doC_CONFIGURATION_FREQ_FROM
          );
          setLowMaxInsurance(
            data[i].doC_CONFIGURATION_FREQ_TO
          );
        }
      }
      else if (
        data[i].doC_CONFIGURATION_NAME ===
        "Driving Licence"
      ) {
        if (
          data[i].doC_CONFIGURATION_LEVEL === "High"
        ) {
          setHighMinLicence(
            data[i].doC_CONFIGURATION_FREQ_FROM
          );
          setHighMaxLicence(
            data[i].doC_CONFIGURATION_FREQ_TO
          );
        }

        else if (
          data[i].doC_CONFIGURATION_LEVEL === "Medium"
        ) {
          setMediumMinLicence(
            data[i].doC_CONFIGURATION_FREQ_FROM
          );
          setMediumMaxLicence(
            data[i].doC_CONFIGURATION_FREQ_TO
          );
        }
        else if (
          data[i].doC_CONFIGURATION_LEVEL === "Low"
        ) {
          setLowMinLicence(
            data[i].doC_CONFIGURATION_FREQ_FROM
          );
          setLowMaxLicence(
            data[i].doC_CONFIGURATION_FREQ_TO
          );
        }
      }

      else if (data[i].doC_CONFIGURATION_NAME === "MOT") {
        if (
          data[i].doC_CONFIGURATION_LEVEL === "High"
        ) {
          setHighMinMOT(
            data[i].doC_CONFIGURATION_FREQ_FROM
          );
          setHighMaxMOT(
            data[i].doC_CONFIGURATION_FREQ_TO
          );
        }

        else if (
          data[i].doC_CONFIGURATION_LEVEL === "Medium"
        ) {
          setMediumMinMOT(
            data[i].doC_CONFIGURATION_FREQ_FROM
          );
          setMediumMaxMOT(
            data[i].doC_CONFIGURATION_FREQ_TO
          );
        }
        else if (
          data[i].doC_CONFIGURATION_LEVEL === "Low"
        ) {
          setLowMinMOT(
            data[i].doC_CONFIGURATION_FREQ_FROM
          );
          setLowMaxMOT(
            data[i].doC_CONFIGURATION_FREQ_TO
          );
        }
      }

      else if (
        data[i].doC_CONFIGURATION_NAME === "Photocard"
      ) {
        if (
          data[i].doC_CONFIGURATION_LEVEL === "High"
        ) {
          setHighMinPhotoCard(
            data[i].doC_CONFIGURATION_FREQ_FROM
          );
          setHighMaxPhotoCard(
            data[i].doC_CONFIGURATION_FREQ_TO
          );
        }

        else if (
          data[i].doC_CONFIGURATION_LEVEL === "Medium"
        ) {
          setMediumMinPhotoCard(
            data[i].doC_CONFIGURATION_FREQ_FROM
          );
          setMediumMaxPhotoCard(
            data[i].doC_CONFIGURATION_FREQ_TO
          );
        }
        else if (
          data[i].doC_CONFIGURATION_LEVEL === "Low"
        ) {
          setLowMinPhotoCard(
            data[i].doC_CONFIGURATION_FREQ_FROM
          );
          setLowMaxPhotoCard(
            data[i].doC_CONFIGURATION_FREQ_TO
          );
        }
      }

      else if (data[i].doC_CONFIGURATION_NAME === "TAX") {
        if (
          data[i].doC_CONFIGURATION_LEVEL === "High"
        ) {
          setHighMinTaxDue(
            data[i].doC_CONFIGURATION_FREQ_FROM
          );
          setHighMaxTaxDue(
            data[i].doC_CONFIGURATION_FREQ_TO
          );
        }

        else if (
          data[i].doC_CONFIGURATION_LEVEL === "Medium"
        ) {
          setMediumMinTaxDue(
            data[i].doC_CONFIGURATION_FREQ_FROM
          );
          setMediumMaxTaxDue(
            data[i].doC_CONFIGURATION_FREQ_TO
          );
        }
        else if (
          data[i].doC_CONFIGURATION_LEVEL === "Low"
        ) {
          setLowMinTaxDue(
            data[i].doC_CONFIGURATION_FREQ_FROM
          );
          setLowMaxTaxDue(
            data[i].doC_CONFIGURATION_FREQ_TO
          );
        }
      }

      if (data[i].doC_CONFIGURATION_TYPE === "Document") {
        if (data[i].doC_CONFIGURATION_NAME === "MOT") {
          if (data[i].doC_CONFIGURATION_ISREQUIRED === true) {
            setMotDocumentCheck(true);
          } else {
            setMotDocumentCheck(false);
          }
        }
        if (data[i].doC_CONFIGURATION_NAME === "TAX") {
          if (data[i].doC_CONFIGURATION_ISREQUIRED === true) {
            setTaxDocumentCheck(true);
          } else {
            setTaxDocumentCheck(false);
          }
        }
        if (data[i].doC_CONFIGURATION_NAME === "Manual Licence") {
          if (data[i].doC_CONFIGURATION_ISREQUIRED === true) {
            SetManualLicenceCheck(true);
          } else {
            SetManualLicenceCheck(false);
          }
        }
        if (data[i].doC_CONFIGURATION_NAME === "Manual Vehicle") {
          if (data[i].doC_CONFIGURATION_ISREQUIRED === true) {
            SetManualVehicleCheck(true);
          } else {
            SetManualVehicleCheck(false);
          }
        }
      }
    }
  }

  const onBtnRefreshAllClick = (e: any) => {
    getRefreshAllOrganizationData();
  };

  const getRefreshAllOrganizationData = () => {
    setIsLoading(true);
    axios
      .get(`Admin/ReCheckOrganization`, Headers)
      .then((response) => {
        setIsLoading(false);
      })
      .catch((ex) => {
        console.log("Getting some API error when Refresh all!");
      });
  };

  const onChangeOrganizationTags = (e: any) => {
    organizationTagsAllData.current = e.target.value.join(', ');
    if(e.target.value) {
      if(e.target.value.length <= 3) {
        for (let i = 0; i <= e.target.value.length; i++) {
          setOrgTagsData(e.target.value);
        }
        const updatedData = organizationTagsData.map(item => 
          Object.assign({}, item, { disabled: false })
        );
        setOrganizationTagsData(updatedData);
      } 
      if (e.target.value.length == 3) {
        const updatedData = organizationTagsData.map(item => 
          Object.assign({}, item, { disabled: true })
        );
        setOrganizationTagsData(updatedData);
      }
    }
  };

  return openConfigurationScreen ? (
    <div className="configuration">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Container className="pb_25">
            <Row>
              <Col className="statusCard">
                <span>
                  You can set all the configuration related to Duty of Care
                </span>
              </Col>
            </Row>
          </Container>
          <Container className="mb_25 ConfigurationBorder rounded">
            <Row>
              <Col
                xs={4}
                sm={4}
                xl={4}
                className="text-center pt_30 font_14 fw-bold"
              >
                <span>Auto-check Frequency</span>
              </Col>
              <Col
                xs={4}
                sm={4}
                xl={4}
                className="text-center pt_30 font_14 fw-bold border-start"
              >
                <span>Driving Licence</span>
              </Col>
              <Col
                xs={4}
                sm={4}
                xl={4}
                className="text-center pt_30 font_14 fw-bold border-start"
              >
                <span>Vehicle</span>
              </Col>
            </Row>
            <Row>
              <Col xs={4} sm={4} xl={4} className="text-center pt_30 font_14">
                <span> User</span>
              </Col>
              <Col
                xs={2}
                sm={2}
                xl={2}
                className="pt_30 text-center border-start centerAlign"
              >
                <FormField
                  editor={<Input
                    type="number"
                    disabled={isdisabled}
                    value={userLicenceFrequency}
                    onChange={(e) => {
                      setUserLicenceFrequency(e.value);
                    }}
                    style={{ width: '100px' }} />}
                />
              </Col>
              <Col xs={2} sm={2} xl={2} className="pt_30 text-center centerAlign">
                <DropDownNative
                  name="userLicence"
                  value={userLicenceOption}
                  data={[
                    { label: "Hours", value: "Hours" },
                    { label: "Days", value: "Days" },
                  ]}
                  disabled={isdisabled}
                  onChange={(e) => {
                    setUserLicenceOption(e.value);
                  }}
                  style={{ width: '100px' }}
                />
              </Col>
              <Col
                xs={2}
                sm={2}
                xl={2}
                className="pt_30 text-center border-start centerAlign"
              >
                <FormField
                  editor={<Input
                    type="number"
                    disabled={isdisabled}
                    value={userVehicleFrequency}
                    onChange={(e) => {
                      setUserVehicleFrequency(e.value);
                    }}
                    style={{ width: '100px' }} />}
                />
              </Col>
              <Col xs={2} sm={2} xl={2} className="pt_30 text-center centerAlign">
                <DropDownNative
                  name="userVehicle"
                  value={userVehicleOption}
                  data={[
                    { label: "Hours", value: "Hours" },
                    { label: "Days", value: "Days" },
                  ]}
                  disabled={isdisabled}
                  onChange={(e) => {
                    setUserVehicleOption(e.value);
                  }}
                  style={{ width: '100px' }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={4} sm={4} className="text-center pt_30 font_14">
                <span> Admin</span>
              </Col>
              <Col
                xs={2}
                sm={2}
                xl={2}
                className="pb_30 pt_30 text-center border-start centerAlign"
              >
                <FormField
                  editor={<Input
                    type="number"
                    disabled={isdisabled}
                    value={adminLicenceFrequency}
                    onChange={(e) => {
                      setAdminLicenceFrequency(e.value);
                    }}
                    style={{ width: '100px' }} />}
                />
              </Col>
              <Col
                xs={2}
                sm={2}
                xl={2}
                className="pb_30 pt_30 text-center centerAlign"
              >
                <DropDownNative
                  name="adminLicence"
                  value={adminLicenceOption}
                  data={[{ label: "Days", value: "Days" }]}
                  disabled={isdisabled}
                  onChange={(e) => {
                    setAdminLicenceOption(e.value);
                  }}
                  style={{ width: '100px' }}
                />
              </Col>
              <Col
                xs={2}
                sm={2}
                xl={2}
                className="pb_30 pt_30 text-center border-start centerAlign"
              >
                <FormField
                  editor={<Input
                    type="number"
                    disabled={isdisabled}
                    value={adminVehicleFrequency}
                    onChange={(e) => {
                      setAdminVehicleFrequency(e.value);
                    }}
                    style={{ width: '100px' }} />}
                />
              </Col>
              <Col
                xs={2}
                sm={2}
                xl={2}
                className="pt_30 pb_30 text-center centerAlign"
              >
                <DropDownNative
                  name="adminVehicle"
                  value={adminVehicleOption}
                  data={[{ label: "Days", value: "Days" }]}
                  disabled={isdisabled}
                  onChange={(e) => {
                    setAdminVehicleOption(e.value);
                  }}
                  style={{ width: '100px' }}
                />
              </Col>
            </Row>
          </Container>
          <Container className="mb_25 ConfigurationBorder rounded">
            <Row>
              <Col xs={4} sm={4} xl={4} className="text-center pt_30 pb_30 font_14">
                <span> Expired Details</span>
              </Col>
              <Col
                xs={2}
                sm={2}
                xl={2}
                className="pt_30 pb_30 text-center centerAlign border-start"
              >
                <FormField
                  editor={<Input
                    type="number"
                    disabled={isdisabled}
                    value={expiredDetailsFrequency}
                    onChange={(e) => {
                      setExpiredDetailsFrequency(e.value);
                    }}
                    style={{ width: '100px' }} />}
                />
              </Col>
              <Col xs={2} sm={2} xl={2} className="pt_30 pb_30 text-center">
                <DropDownNative
                  value={expiredDetailsOption}
                  data={[
                    { label: "Days", value: "Days" },
                    { label: "Months", value: "Months" },
                    { label: "Years", value: "Years" },
                  ]}
                  disabled={isdisabled}
                  onChange={(e) => {
                    setExpiredDetailsOption(e.value);
                  }}
                  style={{ width: '100px' }}
                />
              </Col>
              <Col xs={4} sm={4} xl={4} className="pt_30 pb_30">
                <span style={{ fontSize: "12px" }}>
                  You are able to visualise last {expiredDetailsFrequency}{" "}
                  {expiredDetailsOption} data on dashboard
                </span>
              </Col>
            </Row>
          </Container>
          <Container className="mb_25 ConfigurationBorder rounded">
            <Row>
              <Col xs={4} sm={4} xl={4} className="text-center pt_30 pb_30 font_14">
                <span> Document attachment required</span>
              </Col>
              <Col
                xs={2}
                sm={2}
                xl={2}
                className="pt_30 pb_30 text-center centerAlign border-start"
              >
                <Checkbox
                  disabled={isdisabled}
                  onChange={(e: any) => {
                    setMotDocumentCheck(e.target.value);
                  }}
                  checked={motDocumentCheck}>MOT</Checkbox>
              </Col>
            </Row>
          </Container>

          <Container className="mb_25 ConfigurationBorder rounded">
            <Row>
              <Col xs={4} sm={4} xl={4} className="text-center pt_30 pb_30 font_14">
                <span> Manual detail required</span>
              </Col>
              <Col
                xs={2}
                sm={2}
                xl={2}
                className="pt_30 pb_30 text-center centerAlign border-start"
              >
                <Checkbox
                  disabled={isdisabled}
                  onChange={(e: any) => {
                    SetManualLicenceCheck(e.target.value);
                  }}
                  checked={manualLicenceCheck}>Licence</Checkbox>
              </Col>
              <Col
                xs={2}
                sm={2}
                xl={2}
                className="pt_30 pb_30 text-center centerAlign"
              >
                <Checkbox
                  disabled={isdisabled}
                  onChange={(e: any) => {
                    SetManualVehicleCheck(e.target.value);
                  }}
                  checked={manualVehicleCheck}>Vehicle</Checkbox>
              </Col>
              <Col xs={6} sm={6} xl={6} className="border-end"></Col>
            </Row>
          </Container>

          <Container className="mb_25 ConfigurationBorder rounded">
            <Row>
              <Col xs={4} sm={4} xl={4} className="text-center pt_30 pb_30 font_14">
                <span> Organisation Tags</span>
              </Col>
              <Col
                xs={2}
                sm={2}
                xl={2}
                className="pt_30 pb_30 text-center centerAlign border-start"
                style={{ width:"380px", paddingLeft: "50px"}}
              >
                <MultiSelect
                  disabled={isdisabled}
                  value={orgTagsData}
                  onChange={(e) => {
                    onChangeOrganizationTags(e);
                  }}
                  data={organizationTagsData}
                  inputWrapperProps={{ style: { width: '300px' } }}
                />
              </Col>
            </Row>
          </Container>
          <Container className="mb_25 ConfigurationBorder rounded">
            <Row>
              <Col xs={4} sm={4} xl={4} className="text-center pt_30 pb_30 font_14">
                <div style={{paddingTop: '70px'}}>
                  <span> Send Email Alert</span>
                </div>
              </Col>
              <Col
                xs={4}
                sm={4}
                xl={4}
                className="pt_30 pb_30 text-center centerAlign border-start"
              >
                <Row>
                  <div style={{ marginLeft: '20px' }}>
                  <div style={{marginRight:'45px', marginBottom: '5px' }}>
                    <Checkbox
                      disabled={isdisabled}
                      onChange={(e: any) => {
                        setEndAdditionOfLicenceCheck(e.target.value);
                      }}
                      checked={endAdditionOfLicenceCheck}>Endorsements During Addition of Licence</Checkbox>
                  </div>
                  <div style={{ marginLeft: '5px', marginBottom: '5px' }}>
                    <Checkbox
                      disabled={isdisabled}
                      onChange={(e: any) => {
                        setEndRecheckRefreshLicenceCheck(e.target.value);
                      }}
                      checked={endRecheckRefreshLicenceCheck}>Endorsements During Recheck/Refresh of Licence</Checkbox>
                  </div>
                  <div style={{ marginRight: '133px', marginBottom: '5px' }}>
                    <Checkbox
                      disabled={isdisabled}
                      onChange={(e: any) => {
                        setManualVehicleApprovedCheck(e.target.value);
                      }}
                      checked={manualVehicleApprovedCheck}>Approved Manual Vehicles</Checkbox>
                  </div>
                  <div style={{marginRight: '115px', marginBottom: '5px' }}>
                    <Checkbox
                      disabled={isdisabled}
                      onChange={(e: any) => {
                        setmanualLicenceApprovalCheck(e.target.value);
                      }}
                      checked={manualLicenceApprovalCheck}>Manual Licenses for Approval</Checkbox>
                  </div>
                  <div style={{ marginRight: '172px' }}>
                    <Checkbox
                      disabled={isdisabled}
                      onChange={(e: any) => {
                        setProvisionalLicencesCheck(e.target.value);
                      }}
                      checked={provisionalLicencesCheck}>Provisional Licenses</Checkbox>
                  </div>
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
          <Container className="mb_25 ConfigurationBorder rounded">
            <Row className="pl_0 pr_0">
              <Col xs={12} md={12} lg={6} className="frequencyTop">
                {callFrequency ? (
                  <Frquency
                    obj={{
                      index: 0,
                      frequencyName: "Driving Licence",
                      frequencyTitle:
                        "Driving Licence: Set frequency for days duration",
                      isDisabled: isdisabled,
                      highMinVal: highMinLicence,
                      highMaxVal: highMaxLicence,
                      mediumMinVal: mediumMinLicence,
                      mediumMaxVal: mediumMaxLicence,
                      lowMinVal: lowMinLicence,
                      lowMaxVal: lowMaxLicence,
                    }}
                  />
                ) : null}
              </Col>
              <Col xs={12} md={12} lg={6} className="frequencyTop frequencyBottom">
                {callFrequency ? (
                  <Frquency
                    obj={{
                      index: 12,
                      frequencyName: "Insurance",
                      frequencyTitle: "Insurance: Set frequency for days duration",
                      isDisabled: isdisabled,
                      highMinVal: highMinInsurance,
                      highMaxVal: highMaxInsurance,
                      mediumMinVal: mediumMinInsurance,
                      mediumMaxVal: mediumMaxInsurance,
                      lowMinVal: lowMinInsurance,
                      lowMaxVal: lowMaxInsurance,
                    }}
                  />
                ) : null}
              </Col>
            </Row>
            <Row className="pl_0 pr_0">
              <Col xs={12} md={12} lg={6} className="frequencyTop">
                {callFrequency ? (
                  <Frquency
                    obj={{
                      index: 3,
                      frequencyName: "TAX",
                      frequencyTitle: "Tax Due: Set frequency for days duration",
                      isDisabled: isdisabled,
                      highMinVal: highMinTaxDue,
                      highMaxVal: highMaxTaxDue,
                      mediumMinVal: mediumMinTaxDue,
                      mediumMaxVal: mediumMaxTaxDue,
                      lowMinVal: lowMinTaxDue,
                      lowMaxVal: lowMaxTaxDue,
                    }}
                  />
                ) : null}
              </Col>
              <Col xs={12} md={12} lg={6} className="frequencyTop">
                {callFrequency ? (
                  <Frquency
                    obj={{
                      index: 6,
                      frequencyName: "MOT",
                      frequencyTitle: "MOT: Set frequency for days duration",
                      isDisabled: isdisabled,
                      highMinVal: highMinMOT,
                      highMaxVal: highMaxMOT,
                      mediumMinVal: mediumMinMOT,
                      mediumMaxVal: mediumMaxMOT,
                      lowMinVal: lowMinMOT,
                      lowMaxVal: lowMaxMOT,
                    }}
                  />
                ) : null}
              </Col>
            </Row>
          </Container>
          <Container>
            <Row className="pl_0 pr_0">
              <Col xs={12} md={12} lg={12} className="pl_0 pr_0">
                <div className="ConfigButton fr">
                  {showSaveButton ? (
                    <Button
                      text="Cancel"
                      onClick={onBtnCancelClick}
                      disabled={buttonDisbled}
                      size="large"
                      minWidth="100px"
                    />
                  ) : null}
                </div>
                <div className="ConfigButton fr">
                  {showSaveButton ? (
                    <Button
                      text="Save"
                      accent="teal"
                      onClick={onBtnSaveclick}
                      disabled={buttonDisbled}
                      size="large"
                      minWidth="100px"
                    />
                  ) : null}
                </div>
                <div className="ConfigButton fr">
                  {showSaveButton ? null : (
                    <Button
                      text="Edit"
                      accent="teal"
                      onClick={onBtnEditClick}
                      size="large"
                      minWidth="100px"
                    />
                  )}
                </div>
                <div className="ConfigButton fr">
                  {showSaveButton ? null : (
                    <Button
                      text="Refresh All"
                      accent="green"
                      onClick={onBtnRefreshAllClick}
                      size="large"
                      minWidth="100px"
                    />
                  )}
                </div>
                <div className="ConfigButton fr">
                  {showSaveButton ? (
                    <Button
                      text="Default values"
                      accent="green"
                      onClick={onBtnSetDefaultClick}
                      disabled={buttonDisbled}
                      size="large"
                      minWidth="100px"
                    />
                  ) : null}
                </div>
              </Col>
            </Row>
          </Container>
          <div>
            {showSetDefaulrConfirmationPopup ? (
              <Confirm
                visible
                type="question"
                heading="Confirm"
                onPrimaryButtonClick={onBtnConfirmationSaveClick}
                onCancelButtonClick={onBtnConfirmationCancelClick}
                onClose={() => setShowSetDefaulrConfirmationPopup(false)}
                text="Are you sure you want to continue? This operation may take a while."
              ></Confirm>
            ) : null}
          </div>
        </>
      )}
    </div>
  ) : null;
}
export default Configuration;
